// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase : {
    apiKey: "AIzaSyAVp_wR4-XixEWG7Qq_L3qAYXzWAvw2PRE",
    authDomain: "kenshocyberblog.firebaseapp.com",
    databaseURL: "https://kenshocyberblog-default-rtdb.firebaseio.com",
    projectId: "kenshocyberblog",
    storageBucket: "kenshocyberblog.appspot.com",
    messagingSenderId: "1034505032647",
    appId: "1:1034505032647:web:294f598706866fd04a3fe2",
    measurementId: "G-2N4QHY9GZ1"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
